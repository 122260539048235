<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('users') }}
        </h1>
        <b-button
          :to="{ name: 'new-member', params: { userId: userId } }"
          variant="primary"
        >
          {{ $t('create', { type: $t('user') }) }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <users-table
            :users="users.filter(user => user.id !== currentUser.id)"
            :pagination="pagination"
          />
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import UsersTable from '@/views/users/MembersTable.vue'

export default {
  components: {
    UsersTable,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters('users', ['users', 'pagination']),
    ...mapGetters('auth', ['currentUser']),

    userId() {
      if (this.currentUser.role_name === 'customer') {
        return this.currentUser.id
      }
      if (this.currentUser.role_name === 'admin') {
        return this.$route.params.userId
      }
      return null
    },
  },

  async created() {
    try {
      this.loading = true
      this.selectCustomerId(this.$route.params.userId)
      await this.fetchUsers({ by_customer: this.$route.params.userId })
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  },

  methods: {
    ...mapActions('users', ['fetchUsers']),
    ...mapMutations('customers', {
      selectCustomerId: 'SELECT_CUSTOMER_ID',
    }),
  },
}
</script>

<style></style>
