<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-table
        :items="users"
        :fields="fields"
        responsive
      >
        <template #cell(role_name)="data">
          <b-badge :variant="data.value | roleColor">
            {{ data.value | roleName }}
          </b-badge>
        </template>

        <template #cell(active_status)="data">
          <b-badge :variant="data.value ? 'success' : 'danger'">
            {{ data.value ? $t('active') : $t('unactive') }}
          </b-badge>
        </template>

        <template #cell(scope)="data">
          <div v-if="data.item.scoped_roles.length">
            <span
              v-for="scope in data.item.scoped_roles"
              :key="scope.role_resource_id"
              class="mr-1"
            >
              {{ scope.role_resource_name }}
            </span>
          </div>
        </template>

        <template #cell(created_at)="data">
          {{ data.value | date }}
        </template>

        <template #cell(tools)="data">
          <b-dropdown
            id="dropdown-1"
            size="sm"
            dropright
            no-caret
            variant="outline-none"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                color="#000"
              />
            </template>
            <b-dropdown-item
              v-b-modal="`edit-user-${data.item.id}`"
              @click="selectedUser = { ...data.item }"
            >
              {{ $t('edit') }}
            </b-dropdown-item>
            <b-modal
              :id="`edit-user-${data.item.id}`"
              title="Editar usuario"
              @ok="handleEditUser()"
            >
              <b-form>
                <b-form-group label="Nombre">
                  <b-form-input v-model="selectedUser.name" />
                </b-form-group>
              </b-form>
            </b-modal>

            <!--<b-dropdown-item
            :to="{ name: 'editUser', params: { id: data.item.id } }"
          >{{ $t('edit') }}</b-dropdown-item>-->
          <b-dropdown-item @click="deactivate(data.item)">
            <span
              :class="data.item.active_status ? 'text-danger' : 'text-success'"
            >
              {{
                data.item.active_status ? $t('deactivate') : $t('reactivate')
              }}
            </span>
          </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-overlay>
    <b-pagination
      class="mt-1 ml-1"
      :value="pagination.page"
      :total-rows="pagination.total_objects"
      :per-page="pagination.per_page"
      @change="handlePageChange($event)"
    />
  </b-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      selectedUser: {},
      loading: false,
      fields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'created_at',
          label: 'fecha de alta',
        },
        {
          key: 'active_status',
          label: this.$t('status'),
        },
        {
          key: 'tools',
          label: '',
        },
      ],
    }
  },
  computed: {
    // ...mapGetters('users', ['users']),
  },

  methods: {
    ...mapMutations('users', {
      changePage: 'CHANGE_PAGE',
    }),
    ...mapActions('users', ['fetchUsers', 'editUser', 'deactivateUser']),
    deactivate(user) {
      this.deactivateUser(user).then(() => {
        this.fetchUsers({ params: null })
      })
    },

    handlePageChange(page) {
      this.loading = true
      this.changePage(page)
      // this.fetchUsers({ meta: { pagination: { page } } }).then(() => {
      //   this.loading = false
      // })
    },

    handleEditUser() {
      this.editUser({
        id: this.selectedUser.id,
        name: this.selectedUser.name,
      }).then(() => {
        this.fetchUsers().then(() => {
          this.loading = false
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
